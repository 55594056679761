export enum ContentTypes {
  ARTICLE = 'ARTICLE',
  // AUDIO = 'AUDIO',
  // VIDEO = 'VIDEO',
  // SURVEY = 'SURVEY',
  SERIES = 'SERIES',
  // POLL = 'POLL',
  UNKNOWN = 'UNKNOWN',
}

export interface ContentStep {
  id: string;
  title: string;
  cover: string;
  type: ContentTypes;
  readTime: string;
}

export interface SeriesDetails {
  contentId: string;
  currentStep: number;
  karmaPoints: number;
  nextStepId?: string | null;
  noOfSteps: number;
  noOfCompletedSteps: number;
  prevStepId?: string | null;
}

export interface Content {
  id: string;
  title: string;
  htmlContent?: string;
  pdfContent?: string;
  articleType?: 'html' | 'pdf';
  cover?: string;
  status: 'published' | 'draft';
  createdAt: string;
  updatedAt: string;
  publishedAt?: string;
  type: ContentTypes;
  interaction?: {
    numberOfViews?: number;
    numberOfLikes?: number;
  };
  category?: string;
  steps?: ContentStep[];
  noOfSteps?: number;
  noOfCompletedSteps?: number;
  tags?: string[];
  description?: string;
  owner?: string;
  name?: string;
  disclosure?: string;
  readTime?: string;
  karmaPoints?: number;
  isSeriesStarted?: boolean;
  bookmarked?: boolean;
  contentMark?: 'helpful' | 'unhelpful';
  seriesDetails?: SeriesDetails;
  articleContentUrl?: string;
}

export interface Tag {
  id: string;
  name: string;
  description?: string;
}
