import * as React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { Content } from '@data/contents/Contents.model';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { getContentTypeView } from '../../../logic/CMS/content.logic';
import useContentActions from './useContentActions';

const useContent = (content?: Content) => {
  const navigate = useNavigate();

  const { unpublishContentAction, deleteContentAction } =
    useContentActions(content);

  const createdAt = React.useMemo(() => {
    return moment(content?.createdAt).format('DD MMM YYYY');
  }, [content?.createdAt]);

  const updatedAt = React.useMemo(() => {
    return moment(content?.updatedAt).format('DD MMM YYYY');
  }, [content?.updatedAt]);

  const publishedAt = React.useMemo(() => {
    return moment(content?.publishedAt).format('DD MMM YYYY');
  }, [content?.publishedAt]);

  const contentType = React.useMemo(() => {
    return getContentTypeView(content?.type);
  }, [content?.type]);

  const onContentClick = React.useCallback(
    () => () => {
      navigate(endpointIDReplacer(Endpoints.DISCOVER_PREVIEW, content?.id));
    },
    [content?.id, navigate],
  );

  const htmlContent = React.useMemo(() => {
    const options = {
      replace: (domNode: any) => {
        if (domNode.type === 'tag' && domNode.name === 'body')
          return <div>{domToReact(domNode.children, options)} </div>;
        if (domNode.name === 'html')
          return <div>{domToReact(domNode.children, options)} </div>;
        if (domNode.name === 'head') return null;
        return domNode;
      },
    };

    if (content?.htmlContent) {
      return parse(content?.htmlContent);
    }
    return null;
  }, [content?.htmlContent]);

  const parsedContent = React.useMemo(
    () => ({
      id: content?.id,
      title: content?.title,
      description: content?.description,
      createdAt,
      updatedAt,
      publishedAt,
      contentType,
      onContentClick,
      views: content?.interaction?.numberOfViews,
      likes: content?.interaction?.numberOfLikes,
      cover: content?.cover,
      htmlContent,
      rawHtmlContent: content?.htmlContent,
      steps: content?.steps || [],
      action: {
        label: content?.status === 'published' ? 'Unpublish' : 'Delete',
        onClick: () => {
          if (content?.status === 'published') {
            return unpublishContentAction();
          }
          return deleteContentAction();
        },
      },
      category: content?.category,
      tags: content?.tags,
      owner: content?.owner,
      disclosure: content?.disclosure,
      name: content?.name,
      readTime: content?.readTime,
      noOfSteps: content?.noOfSteps,
      noOfCompletedSteps: content?.noOfCompletedSteps,
      karmaPoints: content?.karmaPoints,
      isStarted: content?.isSeriesStarted,
      contentMark: content?.contentMark,
      bookmarked: content?.bookmarked,
      seriesDetails: content?.seriesDetails,
      pdfURL: content?.articleContentUrl,
    }),
    [
      content?.articleContentUrl,
      content?.id,
      content?.title,
      content?.description,
      content?.interaction?.numberOfViews,
      content?.interaction?.numberOfLikes,
      content?.cover,
      content?.htmlContent,
      content?.steps,
      content?.status,
      content?.category,
      content?.tags,
      content?.owner,
      content?.disclosure,
      content?.name,
      content?.readTime,
      content?.noOfSteps,
      content?.noOfCompletedSteps,
      content?.karmaPoints,
      content?.isSeriesStarted,
      content?.contentMark,
      content?.bookmarked,
      content?.seriesDetails,
      createdAt,
      updatedAt,
      publishedAt,
      contentType,
      onContentClick,
      htmlContent,
      deleteContentAction,
      unpublishContentAction,
    ],
  );

  return parsedContent;
};

export default useContent;
