import { FileUtils, ParamsUtils } from '@utils/index';
import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios, { AxiosResponse } from 'axios';
import { ApiEndpoint } from '@utils/types';
import { Tag } from './Contents.model';

export const fetchContents = createAsyncThunkWithAuth<{ saveToStore: boolean }>(
  'contents/fetchContents',
  async (_, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(urlGenerator(ApiEndpoint.GET_ALL_CONTENT_LIST))
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const getContent = createAsyncThunkWithAuth<{
  id: string;
}>('contents/getContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlGenerator(ApiEndpoint.GET_CONTENT_DETAIL, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const saveArticle = createAsyncThunkWithAuth<{
  id?: string;
  title: string;
  htmlContent: string;
  category: string;
  cover: string;
  readingTime: string;
  tags?: string[];
  description?: string;
  owner?: string;
  name?: string;
  disclosure?: string;
  attachments?: string[];
  articleType?: string;
  pdfContent?: File;
}>('contents/saveArticle', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title).then((cover) => {
      const form = ParamsUtils.jsonToForm({
        html_content: args.htmlContent,
        read_time: args.readingTime,
        name: args.name,
        disclosure_statement: args.disclosure,
        content_category_id: args.category,
        title: args.title,
        owner: args.owner,
        type: 'ContentItem::Article',
        short_description: args.description,
      });

      if (args.attachments)
        for (let i = 0; i < args.attachments?.length; i += 1)
          form.append(`html_attachment_ids[]`, args.attachments[i]);

      if (args.tags)
        for (let i = 0; i < args.tags?.length; i += 1)
          form.append(`tag_id[]`, args.tags[i]);

      if (cover !== null) form.append('cover', cover);

      if (args.articleType === 'html') {
        form.append('html_content', args.htmlContent);
        form.append('article_content_type', 'text/html');
      }

      if (args.articleType === 'pdf' && args.pdfContent) {
        form.append('pdf_content_file', args.pdfContent);
        form.append('article_content_type', 'application/pdf');
      }

      (args.id ? axios.put : axios.post)(
        urlGenerator(
          args.id
            ? ApiEndpoint.UPDATE_CONTENT_ITEMS
            : ApiEndpoint.CONTENT_ITEMS,
          { id: args.id || '' },
        ),
        form,
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  });
});

export const saveSeries = createAsyncThunkWithAuth<{
  id?: string;
  title: string;
  category: string;
  cover: string;
  tags?: string[];
  description?: string;
  owner?: string;
  name?: string;
  disclosure?: string;
  steps?: {
    id: string;
    exclusive: boolean;
  }[];
}>('contents/saveSeries', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title)
      .then((cover) => {
        const form = ParamsUtils.jsonToForm({
          name: args.name,
          disclosure_statement: args.disclosure,
          content_category_id: args.category,
          title: args.title,
          owner: args.owner,
          type: 'ContentItem::Series',
          short_description: args.description,
          summary: args.description,
          sub_type: 'user_initiated',
        });

        if (args.tags)
          for (let i = 0; i < args.tags?.length; i += 1)
            form.append(`tag_id[]`, args.tags[i]);

        if (args.steps) {
          for (let i = 0; i < args.steps?.length; i += 1) {
            form.append(`content_item_id[]`, args.steps[i].id);
            if (args.steps[i].exclusive)
              form.append(`content_exclusive_id[]`, args.steps[i].id);
          }
        }

        if (cover !== null) form.append('cover', cover);

        (args.id ? axios.put : axios.post)(
          urlGenerator(
            args.id
              ? ApiEndpoint.UPDATE_CONTENT_ITEMS
              : ApiEndpoint.CONTENT_ITEMS,
            { id: args.id || '' },
          ),
          form,
        )
          .then((response) => {
            resolve(response.data);
          })
          .catch(reject);
      })
      .catch(reject);
  });
});
export const saveSurvey = createAsyncThunkWithAuth<{
  id?: string;
  title: string;
  category: string;
  cover: string;
  tags?: string[];
  description?: string;
  owner?: string;
  name?: string;
  disclosure?: string;
  surveyContent: {
    surveyLink: string;
    uidCid: boolean;
    standardScore: string;
    formName: string;
    webhook: string;
  };
}>('contents/saveSurvey', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title)
      .then((cover) => {
        const form = ParamsUtils.jsonToForm({
          name: args.name,
          disclosure_statement: args.disclosure,
          content_category_id: args.category,
          title: args.title,
          owner: args.owner,
          type: 'ContentItem::Survey',
          summary: args.description,
        });

        if (args.tags)
          for (let i = 0; i < args.tags?.length; i += 1)
            form.append(`tag_id[]`, args.tags[i]);

        form.append('survey_link', args.surveyContent.surveyLink);
        form.append('is_standard_score', args.surveyContent.standardScore);
        form.append('form_id', args.surveyContent.formName);

        if (cover !== null) form.append('cover', cover);

        (args.id ? axios.put : axios.post)(
          urlGenerator(
            args.id
              ? ApiEndpoint.UPDATE_CONTENT_ITEMS
              : ApiEndpoint.CONTENT_ITEMS,
            { id: args.id || '' },
          ),
          form,
        )
          .then((response) => {
            resolve(response.data);
          })
          .catch(reject);
      })
      .catch(reject);
  });
});

export const uploadAttachment = createAsyncThunkWithAuth<{
  file: File;
}>('contents/uploadAttachment', async (args, urlGenerator) => {
  const form = ParamsUtils.jsonToForm({
    attachment_type: 'contents',
  });

  form.append('attachment_file', args.file);
  return new Promise((resolve, reject) => {
    axios
      .post(urlGenerator(ApiEndpoint.UPLOAD_IMAGE), form)
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const deleteContent = createAsyncThunkWithAuth<{
  id: string;
}>('contents/deleteContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.DELETE_CONTENT, {
          id: args.id,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const publishContent = createAsyncThunkWithAuth<{
  id: string;
}>('contents/publishContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(urlGenerator(ApiEndpoint.PUBLISH_CONTENT, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const unPublishContent = createAsyncThunkWithAuth<{
  id: string;
}>('contents/unpublishContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(urlGenerator(ApiEndpoint.UNPUBLISH_CONTENT, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const createCategory = createAsyncThunkWithAuth<Tag>(
  'contents/createCategory',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .post(urlGenerator(ApiEndpoint.CREATE_CONTENT_CATEGORY), {
          title: args.name,
          short_description: args.description,
          visible: true,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const updateCategory = createAsyncThunkWithAuth(
  'contents/updateCategory',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          urlGenerator(ApiEndpoint.UPDATE_CONTENT_CATEGORY, {
            categoryId: args.id,
          }),
          {
            category_id: args.id,
            title: args.name,
            short_description: args.description,
            visible: true,
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);
export const deleteCategory = createAsyncThunkWithAuth<{ id: string }>(
  'contents/deleteCategory',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          urlGenerator(ApiEndpoint.DELETE_CONTENT_CATEGORY, {
            categoryId: args.id,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const createTag = createAsyncThunkWithAuth<Tag>(
  'contents/createTag',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .post(urlGenerator(ApiEndpoint.CREATE_CONTENT_TAG), {
          name: args.name,
          category: 'content',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const updateTag = createAsyncThunkWithAuth(
  'contents/updateTag',
  async (args, urlGenerator) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .put(
          urlGenerator(ApiEndpoint.UPDATE_CONTENT_TAG, {
            tagId: args.id,
          }),
          {
            name: args.name,
            category: 'content',
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const deleteTag = createAsyncThunkWithAuth<{
  id: string;
}>('contents/deleteTag', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.DELETE_CONTENT_TAG, {
          tagId: args.id,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
export const fetchCategories = createAsyncThunkWithAuth<void>(
  'contents/fetchCategories',
  async (_: void, urlGenerator) => {
    const response = await axios.get(
      urlGenerator(ApiEndpoint.GET_CONTENT_CATEGORIES),
    );
    return response.data;
  },
);

export const fetchTags = createAsyncThunkWithAuth<void>(
  'contents/fetchTags',
  async (_: void, urlGenerator) => {
    const response = await axios.get(
      urlGenerator(ApiEndpoint.GET_CONTENT_TAGS),
    );
    return response.data;
  },
);

export const markContent = createAsyncThunkWithAuth<{
  id: string;
  rating: boolean;
}>('contents/markContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(urlGenerator(ApiEndpoint.MARK_CONTENT, { id: args.id }), {
        rating: String(args.rating),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const markContentAsHelpful = (id: string) =>
  markContent({ id, rating: true });

export const markContentAsNotHelpful = (id: string) =>
  markContent({ id, rating: false });

export const fetchContentsByCategory = createAsyncThunkWithAuth<{
  sort?: 'created_at' | 'view_count' | 'helpful_count';
}>('contents/fetchContentsByCategory', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlGenerator(ApiEndpoint.GET_CONTENTS_BY_CATEGORY, {}), {
        params: {
          sort_by: args.sort || 'created_at',
        },
      })
      .then((response) => {
        const res = response.data.content_categories?.reduce(
          (acc, category) => {
            if (category.content_items?.total_count === 0) return acc;
            return [
              ...acc,
              ...category.content_items?.content_items?.map((item) => ({
                content_item: {
                  ...item,
                  category_name: category.title,
                },
              })),
            ];
          },
          [],
        );
        resolve(res);
      })
      .catch(reject);
  });
});

export const bookmarkContent = createAsyncThunkWithAuth<{
  id?: string;
}>('contents/bookmarkContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(urlGenerator(ApiEndpoint.BOOKMARK_CONTENT, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const unbookmarkContent = createAsyncThunkWithAuth<{
  id?: string;
}>('contents/unbookmarkContent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(urlGenerator(ApiEndpoint.BOOKMARK_CONTENT, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const startSeries = createAsyncThunkWithAuth<{
  id?: string;
}>('contents/startSeries', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(urlGenerator(ApiEndpoint.START_SERIES, { id: args.id }))
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const moveToNextStep = createAsyncThunkWithAuth<{
  contentId?: string;
  seriesId?: string;
}>('contents/moveToNextStep', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.MOVE_TO_NEXT_STEP, { id: args.contentId }),
        {
          series_id: args.seriesId,
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const markSeriesAsCompleted = createAsyncThunkWithAuth<{
  seriesId?: string;
}>('contents/markSeriesAsCompleted', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        urlGenerator(ApiEndpoint.MARK_SERIES_AS_COMPLETED, {
          id: args.seriesId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
