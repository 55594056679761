import React from 'react';
import { ReactComponent as ArticleIcon } from '@assets/icons/content/article.svg';
import { ReactComponent as SeriesIcon } from '@assets/icons/content/series.svg';
import { ReactComponent as SurveyIcon } from '@assets/icons/content/survey.svg';
import { ContentTypes } from '@data/contents/Contents.model';

export const getContentTypeView = (contentType?: ContentTypes) => {
  switch (contentType) {
    case ContentTypes.ARTICLE:
      return {
        value: ContentTypes.ARTICLE,
        label: 'Article',
        icon: (className?: string) => <ArticleIcon className={className} />,
        description: 'A template for text focused content.',
        active: true,
      };
    case ContentTypes.SERIES:
      return {
        value: ContentTypes.SERIES,
        label: 'Series',
        icon: (className?: string) => <SeriesIcon className={className} />,
        description: 'Multiple content in one.',
        active: true,
      };
    // case ContentTypes.AUDIO:
    //   return {
    //     value: ContentTypes.AUDIO,
    //     label: 'Audio',
    //     icon: (className?: string) => <AudioIcon className={className} />,
    //     description: 'Podcast compatible.',
    //     active: false,
    //   };
    // case ContentTypes.POLL:
    //   return {
    //     value: ContentTypes.POLL,
    //     label: 'Poll',
    //     icon: (className?: string) => <PollIcon className={className} />,
    //     description: 'A single question.',
    //     active: false,
    //   };
    //
    // case ContentTypes.SURVEY:
    //   return {
    //     value: ContentTypes.SURVEY,
    //     label: 'Survey',
    //     icon: (className?: string) => <SurveyIcon className={className} />,
    //     description: ' A series of questions.',
    //     active: true,
    //   };
    // case ContentTypes.VIDEO:
    //   return {
    //     value: ContentTypes.VIDEO,
    //     label: 'Video',
    //     icon: (className?: string) => <VideoIcon className={className} />,
    //     description: 'A template for video-focused content.',
    //     active: false,
    //   };
    default:
      return {
        value: ContentTypes.UNKNOWN,
        label: 'Unknown',
        icon: (className?: string) => null,
      };
  }
};
